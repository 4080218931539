import { render, staticRenderFns } from "./InsPresident.vue?vue&type=template&id=3f237f9b&scoped=true&"
import script from "./InsPresident.vue?vue&type=script&lang=ts&"
export * from "./InsPresident.vue?vue&type=script&lang=ts&"
import style0 from "./InsPresident.vue?vue&type=style&index=0&id=3f237f9b&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3f237f9b",
  null
  
)

export default component.exports