











import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
@Component
export default class CmxAboutUs extends Vue {
  hotProducts: any[] = [];
  content: object = {};

  // 获取关于我们cms内容
  getPromotion () {
    // this.$Api.cms.getContentByKey({ key: 'k_ab' }).then((result) => {
    //   this.content = result;
    // });

    var page = 'Home';
    this.$Api.promotion.getPromotion('Home', 1).then(result => {
      if (result.Promotion.PrmtProductList.length > 0) {
        this.hotProducts = result.Promotion.PrmtProductList;
      }
    });
  }

  get isMobile () {
    return this.$store.state.isMobile;
  }

  mounted () {
    this.getPromotion();
  }

  @Watch('isMobile', { deep: true })
  onMediaChange () {
    this.getPromotion();
  }
}
